import { BaseElement, html, css } from 'Elements';
import { Session } from 'Utils';
import './settings/NotificationsIcon.js';
import './settings/SessionIcon.js';
import './settings/SettingsIcon.js';
import './DebugScreenSize.js';
import './Menus/Large.js';
import './Menus/Short.js';
import Logo from '../../Logo.svg.js';

class Header extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
          
        }

        .header_container {
          position: fixed;
          width: 100%;
          top: -70px;
          z-index:905;
          box-shadow:0px 5px 5px 0px #00000044;
        }

        .header_container.visible {
          top: 0px;
        }
        
        .debug {
          position:fixed;
          left:10px;
          bottom:10px;
          background-color:black;
          z-index:1200;
          font-size:0.6em;
          padding:10px;
          color:white;
        }
        
        .header {
          position:relative;
          display:flex;
          justify-content: space-between;
          align-items: center;
          height:86px;
          /*transition: height 0.3s, background-color 0.3s;*/
          border-bottom:1px solid var(--nsys-blue3s);
          background-color: var(--nsys-blue5);
        }

        .header.collapsed {
          /*height: 46px;*/
          height:var(--bar-margin-top);
          background-color: var(--nsys-blue5);
          border-bottom:1px solid var(--nsys-blue3s);
        }

        .short_menu_and_logo {
          display:flex;
          align-items: center;
          flex-grow: 1;
        }

        .red {
          line-height:20px;
          font-size:0.75em;
          font-weight:bold;
          font-family:Calibri, sans-serif;
          display:flex;
          justify-content: space-between;
          white-space:nowrap;
          text-decoration:none;
          height:20px;
          opacity:1;
          /*transition: opacity 0.3s;*/
          position:absolute;
          z-index:2;
        }

        .red a {
          color:white;
          text-decoration:none;
        }

        .red a:hover {
          text-decoration:underline;
        }

        .red.hidden {
          opacity:0;
        }

        .urgence {
          padding-left:10px;
          padding-right:20px;
          background-color:red;
          color:white;
          text-decoration:none;
          line-height:22px;
          font-weight:bold;
          font-family:Calibri, sans-serif;
        }

        .white {
          width:200px;
          color:black;
          flex-grow: 1;
          text-align:center;
        }

        .white a {
          color:var(--nsys-black);
          text-decoration:none;
          padding-right:30px;
        }

        .white a:hover {
          text-decoration:underline;
        }

        .separator {
          border-left: 15px solid red;
          width:0px;
          background-color:transparent;
          position:relative;
          transform:rotate(0.5turn);
        }

        .separator:before {
          content: '.';
          width: 1px;
          display: block;
          color: red;
          border-left: 40px solid red;
          position: absolute;
          left: -28px;
          transform: skewX(-45deg);
          top: 0px;
          overflow: hidden;
        }

        .logo {
          min-width:200px;
          flex-grow: 1;
        }

        .logo svg {
          height:60px;
          display:block;
          transition: height 0.3s;
        }

        .logo.collapsed svg {
          height:40px;
        }

        .title {
          white-space: nowrap;
          font-style:italic;
          line-height:40px;
          padding-left:30px;
          padding-right:30px;
          display:inline-block;
        }

        .center_container {
          width:100%;
          padding-left:20px;
          height:100%;
          display: flex;
          justify-content: flex-start;
        }

        .collapsed .center_container {
          padding-left:0px;
        }

        .right_container {
          display:flex;
          align-items: center;
          margin-right:20px;
          gap:10px;
          max-width:220px;
          flex-grow: 1;
          justify-content: flex-end;
          padding-top:2px;
        }

        .right_container > * {
          min-width:30px;
          max-width:30px;
          width:30px;
          margin-left: 6px;
        }

        .right_container.anonymous > * {
          min-width:initial;
          max-width:initial;
          width:initial;
        }


        @media print {
          :host {
            position: relative;
            display:none;
          }
        
          .logo .st0 {
            fill: #495154;
          }

          .red {
            display:none;
          }
        }

        @media (max-width: 670px) {
          .logo {
            min-width:0px;
          }

          .red {
            font-size:0.6em;
          }


          .right_container {
            margin-right:10px;
            gap:5px;
          }
        }

    `];
  }
  
  static get properties() {
    return {
      menu: { type: Object },
    }
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            emergency: 'Emergency'
          }
        },
        french:{
          translation: {
            emergency: 'Urgence'
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.handleScroll = this.handleScroll.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handlePopstate = this.handlePopstate.bind(this);
  }

  handleThemeChanged(event) {
    this.logo = this.logo || this.shadowRoot.querySelector('#logo');
    const themeChangeEvent = new CustomEvent('theme-changed', {
      detail: { isDark: event.detail.isDark },
      bubbles: true, // L'événement traverse le DOM
      composed: true // L'événement traverse le Shadow DOM
    });
    this.dispatchEvent(themeChangeEvent);
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('popstate', this.handlePopstate);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('popstate', this.handlePopstate);
  }

  firstUpdated() {
    super.firstUpdated();
    setTimeout(() => {
      this.qs('.header_container').classList.add('visible');
    }, 200);
    this.handleScroll();
  }

  handleResize() {
    this.handleScroll();
  }

  handleScroll() {
    this.redDiv = this.redDiv || this.qs('.red');
    this.header = this.header || this.qs('.header');
    this.logo = this.logo || this.qs('.logo');
    let max = 0;
    if (window.innerWidth >= 670) {
      max = 20;
    }

    this.header.classList.add('collapsed');
    this.logo.classList.add('collapsed');
    document.body.classList.add('header_collapsed');
      /*

    if (Session.isAuthenticated()) {
      this.header.classList.add('collapsed');
      this.logo.classList.add('collapsed');
      document.body.classList.add('css_connected');
      return;
    }

    //this.shadowRoot.querySelector('.debug').innerText = window.scrollY + ' >= ' + max;

    if (window.scrollY >= max) {
      //this.redDiv.classList.add('hidden');
      this.header.classList.add('collapsed');
      this.logo.classList.add('collapsed');
      document.body.classList.add('header_collapsed');
    } else {
      if (window.scrollY >= 0) {
        //this.redDiv.classList.remove('hidden');
        this.header.classList.remove('collapsed');
        this.logo.classList.remove('collapsed');
        document.body.classList.remove('header_collapsed');
      }
    }
    */
  }

  handlePopstate() {
    this.requestUpdate();
  }

  render() {
    const showRed = !Session.isAuthenticated() && window.location.pathname === '/public';

    return html`
      <div class="header_container">
        <div class="header collapsed">
          <div class="short_menu_and_logo">
            <header-menu-short .menu=${this.menu}></header-menu-short>
            <a href="/" class="logo collapsed">${Logo}</a>
          </div>
          <div class="center_container">
            <header-menu-large .menu=${this.menu}></header-menu-large>
          </div>
          <div class="right_container ${!Session.isAuthenticated() ? 'anonymous' : ''}">
            <!-- <basket-icon></basket-icon> -->
            <!-- <notifications-icon></notifications-icon> -->
            <session-icon></session-icon>
            <settings-icon></settings-icon>
          </div>
        </div>
        ${ showRed ? html`<div class="red">
          <div class="urgence">${this._tl('emergency')} 24/7 | <a href="mailto:csirt@sysdream.io">csirt@sysdream.io</a> | <a href="tel:+33183070006">+33(0)1 83 07 00 06</a></div>
          <div class="separator">&nbsp;</div>
          <div class="white">&nbsp;</div>
        </div>` : ''}
      </div>
      <!-- <div class="debug">bla</div> -->
    `;
  }
}

customElements.define('app-header', Header);